<template>
    <v-app>
        <router-view/>
    </v-app>
</template>

<script>

export default {
    data() {
        const userProfile = this.$cookies.get("LINE_ALCON_LIFF_DATA")
        return {
            userProfile,
        }
    },
    async mounted() {
        if (this.userProfile === null) {
            this.$router.push('/error7?page=app&message=ไม่มีไลน์userid')
        }
        let path = $cookies.get("LINE_LIFF_ORIGIN_PATH")
        if(path !== undefined && path !== null && path !== "undefined" && path !== "null") {
           await this.$router.push(path)
           await $cookies.remove("LINE_LIFF_ORIGIN_PATH")
        } 
      
    },
    methods: {
    },
}

</script>

<style lang="scss" scoped>
</style>
